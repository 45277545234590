export default [
  "formkit.config.js",
  "formkit.config.js",
  "/var/www/html/mirage/frontend/components/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "/var/www/html/mirage/frontend/components/global/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "/var/www/html/mirage/frontend/components/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "/var/www/html/mirage/frontend/layouts/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "/var/www/html/mirage/frontend/pages/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "/var/www/html/mirage/frontend/plugins/**/*.{js,ts,mjs}",
  "/var/www/html/mirage/frontend/composables/**/*.{js,ts,mjs}",
  "/var/www/html/mirage/frontend/utils/**/*.{js,ts,mjs}",
  "/var/www/html/mirage/frontend/{A,a}pp.{vue,js,jsx,mjs,ts,tsx}",
  "/var/www/html/mirage/frontend/{E,e}rror.{vue,js,jsx,mjs,ts,tsx}",
  "/var/www/html/mirage/frontend/app.config.{js,ts,mjs}",
  "/var/www/html/mirage/frontend/formkit.config.js"
]